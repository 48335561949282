import Config from './src/interfaces/Config';

const config: Config = {
  siteTitle: 'Toposware EEAP Site',
  twitterName: '@toposware',
  title: 'Toposware EEAP Site',
  rootUrl: '/', // used only for the social card atm, required (update when using other environment)
  protectedRoutePrefix: '/protected', // slash at the beginning, but not at the end. Also the page file name has to match.
  includeFolder: ['features-test', 'content', 'legal'],
  indexPath: '', // could be content/module-1/introduction.html for example or any path to a html page
  includeProtectedFolder: ['members'],
  protectedPagesPath: 'src/protected/pages',
  protectedLayoutsPath: 'src/protected/layout',
  pageTransitionDuration: 0.2,
  enableH3Toc: true,
  headerOffset: 100,
  docSearch: {
    appId: 'S36OISFKTX',
    apiKey: 'fc77ad501873a0a81b4c39e02a4aaaac',
    indexName: 'crawler_Toposware docs platform (new)',
    placeholder: 'Search documentation',
  },
  navigation: [
    {
      label: 'Home',
      path: '/protected/members/introduction.html',
    },
    {
      label: 'Educate',
      content: [
        {
          label: 'Zero Knowledge Overview',
          path: '/protected/members/overview.html',
        },
        {
          label: 'Zero Knowledge Proof Basics',
          path: '/protected/members/zkpbasics.html',
        },
        {
          label: 'ZKP Examples',
          path: '/protected/members/zkp_examples.html',
        },
        {
          label: 'WIP: Zero Knowledge Use Cases',
          content: [
            {
              label: 'Authentication',
              path: '/protected/members/use_cases_authent.html',
            },
            {
              label: 'Identity Protection',
              path: '/protected/members/use_cases_idprotect.html',
            },
            {
              label: 'Anonymous Payments',
              path: '/protected/members/use_cases_payments.html',
            },
            {
              label: 'Verifiable Computation',
              path: '/protected/members/use_cases_verifcomp.html',
            },
            {
              label: 'On-Chain Voting',
              path: '/protected/members/use_cases_voting.html',
            },
            {
              label: 'Online Gambling',
              path: '/protected/members/use_cases_gambling.html',
            },
          ],
        },
        {
          label: 'disabled: Topos 101',
          path: '/protected/members/topos101.html',
        },
        {
          label: 'disabled: Upcoming Webinars',
          path: '/protected/members/upcomingwebinars.html',
        },
        {
          label: 'Recordings and Presentations',
          path: '/protected/members/presentations.html',
        },
        {
          label: 'Glossary',
          path: '/protected/members/glossary.html',
        },
        {
          label: 'disabled: Certification',
          path: '/protected/members/certification.html',
        },
      ],
    },
        {
      label: 'Innovate',
      content: [
        {
          label: 'Topos Key Concepts',
          path: '/protected/members/key_concepts.html',
        },
        {
          label: 'WIP: Native Interoperability',
          path: '#native-interop',
        },
        {
          label: 'WIP: Privacy and Security',
          path: '/protected/members/privacy_security.html',
        },
        {
          label: 'WIP: Scalability',
          path: '/protected/members/scalability.html',
        },
        {
          label: 'Topos Differentiated Solutions',
          content: [
            {
              label: 'Cross-Asset Digital Ecosystem',
              path: '/protected/members/cadecosystem.html',
            },
            {
              label: 'zkAudit',
              path: '/protected/members/zkaudit.html',
            },
            {
              label: 'disabled: RWA Verification',
              path: '/protected/members/rwaverification.html',
            },
            {
              label: 'disabled:Supply Chain',
              path: '#supply-chain',
            },
            {
              label: 'ZK Machine Learning',
              path: '/protected/members/zkml.html',
            },
          ],
        },
        {
          label: 'disabled: Business Consultation',
          path: '/protected/members/biz_consult.html',
        },
        {
          label: 'disabled: Web3 Innovations',
          path: '/protected/members/web3.html',
        },
      ],
    },
    {
      label: 'Access',
      content: [
        {
          label: 'disabled: Developer Training',
          path: '/protected/members/dev_training.html',
        },
        {
          label: 'disabled: Toposware Permissioned Subnet',
          path: '/protected/members/perm_subnet.html',
        },
        {
          label: 'disabled: Testnet',
          path: '/protected/members/testnet.html',
        },
        {
          label: 'disabled: Deploy Private Subnet',
          path: '/protected/members/deploy_private_subnet.html',
        },
        {
          label: 'WIP: Documentation',
          path: '/protected/members/docs.html',
        },
      ],
    },

    {
      label: 'Accelerate',
      content: [
        {
          label: 'WIP: Expert Support',
          path: '/protected/members/support.html',
        },
        {
          label: 'disabled: Roadmap Consultation',
          path: '/protected/members/roadmap_consult.html',
        },
        {
          label: 'disabled: Networking',
          path: '/protected/members/networking.html',
        },
        {
          label: 'disabled: POC Support',
          path: '/protected/members/poc_support.html',
        },
        {
          label: 'disabled: Partner',
          path: '#partner',
        },
      ],
    },
    {
      label: 'Engage',
      content: [
        {
          label: 'Contact Us & Office Hours',
          path: '#contactus',
        },
        {
          label: 'Recommended Articles',
          path: '/protected/members/articles.html',
        },
        {
          label: 'Blog',
          path: '/protected/members/blog.html',
        },
        {
          label: 'Social Media',
          path: '#social-media',
        },
        {
          label: 'disabled: News and Multimedia',
          path: '#news-and-multimedia',
        },
        {
          label: 'disabled: Coming Soon - Member Directory',
          path: '#member-director',
        },
        {
          label: 'disabled: Topos Directory',
          path: '#topos-directory',
        },
        {
          label: 'disabled: FAQ',
          path: '#faq',
        },
      ],
    },
  ],
  footerLinks: [
    {
      label: 'Privacy Policy',
      path: '/legal/privacy-policy.html',
    },
  ],
  storage: {
    cookieConsentKey: 'cookieConsent',
  },
};

export default config;
